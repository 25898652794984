import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  styled,
  Slider,
  Tooltip,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useMemo } from "react";
import { httpClient } from "../../../appUtility/Api";
import moment, { now } from "moment/moment";
import MuiAlert from "@mui/material/Alert";
import PaymentDialog from "../../PaymentDialog";
import { useParams } from "react-router-dom";
//import DoubleArrow from "../../../images/arrow.png"
import DoubleArrow from "../../../images/arroww.svg"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GreyButton = styled("button")(({ theme }) => ({
  background: "transparent",
  padding: "10px 20px",
  color: "#777",
  ouline: "none",
  borderRadius: "25px",
  border: "1px solid #777",
  marginRight: "10px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    background: "#777",
    color: "#fff",
  },
}));


const MainDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
  },
}));

const Logo = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  paddingTop: "10px",
  "& span": {
    background: theme.palette.primary.red,
    padding: "5px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "12px 3px px 3px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "4px",
    },
  },
}));

const MainTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Epilogue",
  "& p1": {
    fontSize: "40px",
    fontWeight: "350",
  },
  "& svg": {
    cursor: "pointer",
    position: "relative",
    top: "-35px",
    right: "-15px",
  },
  [theme.breakpoints.down("md")]: {
    "& p1": {
      fontSize: "26px",
    },
  },

}));

const ValueList = styled(DialogTitle)(({ theme }) => ({
  fontFamily: "Epilogue !important",
  marginTop: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& span": {
    marginTop: "10px",
    fontSize: "18px",
    fontWeight: "900",
    whiteSpace: "nowrap",

  },
  "& span1": {
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: "600",


  },
  "& span2": {
    marginTop: "10px",
    color: "grey",
    fontSize: "12px",
    lineHeight: "1.4",

  },
}));



const CauseDiv = styled("div")(({ theme }) => ({
  padding: "40px 15%",
  borderBottom: "1px dashed #999",
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
}));

const ButtonsDiv = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
}));

const ButtonTime = styled("button")(({ theme }) => ({
  fontSize: "22px",
  outline: "none",
  border: "1px solid transparent",
  borderRadius: "25px",
  padding: "10px 20px",
  margin: "5px",
  cursor: "pointer",
}));

const LogoDiv = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  position: "relative",
  top: "83px",
  "& span": {
    background: theme.palette.primary.red,
    padding: "15px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "0px 17px",
    [theme.breakpoints.down("md")]: {
      margin: "0 10px",
      padding: "10px",
    },
  },
}));

const CauseName = styled("div")(({ theme }) => ({
  padding: "16.5px 14px 20px 14px",
  marginTop: "10px",
  border: "0.02px solid rgba(0, 0, 0, 0)",
  borderRadius: "4px",
  transition: "all 0.3s ease",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  color: "#000000",
  textAlign: "center",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const PrettoSlider = styled(Slider)({
  margin: "40px 10px 40px 10px",
  color: 'red',
  height: 2,
  width: "100%",
  '& .MuiSlider-track': {
    border: 'none',
  }, '& .MuiSlider-mark': {

    background: 'unset',

  }, '& .MuiSlider-markLabel': {

    marginTop: "50px",
    fontSize: 16,
    fontFamily: "Epilogue",
    color: '#212121',
    //color: 'red',



  },
  '& .MuiSlider-thumb': {
    height: 30,
    width: 30,
    top: 50,
    backgroundColor: 'red',

    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      top: -70,
      left: -2,
      right: 0,
      bottom: 0,
      backgroundColor: 'red',
      border: '2px solid currentColor',
      transform: 'rotateY(180deg)',
    },
  },

  '& .MuiSlider-valueLabel': {
    top: 75,
    fontSize: 16,
    fontFamily: "Epilogue",
    color: '#dadada',
    background: 'unset',
    //overflow: "hidden",
    padding: 0,
    width: 30,
    height: 30,
    borderRadius: '50% 50% 50% 50%',
    backgroundColor: 'none',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});


const GiveText = styled("div")(({ theme }) => ({
  borderBottom: "2px solid #dadada",
  textAlign: "center",
  "& h2": {
    margin: "0",
    fontSize: "30px",
    paddingBottom: "20px",
    paddingTop: "20px",
  },
  "& span": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 0",
    fontSize: "26px",
    fontWeight: "300",
    paddingBottom: "10px",

  }, "& strong": {
    fontSize: "38px",
    //color: theme.palette.primary.red,
    // margin:"0",
  },
  "& c1": {
    fontSize: "32px",
  },
  "& img": {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  },
  [theme.breakpoints.down("md")]: {
    "& span": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& h2": {
      margin: "0",
      fontSize: "15px",
      paddingBottom: "20px",
    }, "& strong": {
      fontSize: "18px",
      //color: theme.palette.primary.red,
      // margin:"0",
    },
  },
}));

const HourText = styled("div")(({ theme }) => ({
  paddingTop: "20px",
  textAlign: "center",
  "& h3": {
    margin: "0",

  }, "& strong": {
    //fontSize: "25px",
    //color: theme.palette.primary.red,
    // margin:"0",
  },
}));

const FormDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  // padding: "30px 0",
  "& h2": {
    fontSize: "22px",
    // margin:"0",
  },
  "& .MuiTextField-root ": {
    padding: "10px",
    width: "90px",
  },
  "& .MuiSelect-select ": {
    padding: "10px",
    width: "100px",
  },
  "& input": {
    padding: "10px",
  },
}));

const Reddiv = styled("div")(({ theme }) => ({
  background: "#fc4343",
  color: "#fff",
  textAlign: "center",
  padding: "5px",
  position: "relative",
  borderRadius:"10px",
}));

const member = localStorage.getItem("login");
const memberData = JSON.parse(member);

//console.log("mem", memberData);
//console.log("tok", guestToken);

const CauseDialog = (props) => {


  const tok = localStorage.getItem("guestToken");
  const guestToken = JSON.parse(tok);


  const RedButton = styled(Button)(({ theme }) => ({
    background: ((step === 2 && validation) || (step === 1 && validation1)) ? "#ccc" : theme.palette.primary.red,
    color: "#fff",
    padding: "5px 25px",
    borderRadius: "25px",
    outline: "none",
    border: "1px solid transparent",
    margin: "0 10px",
    cursor: "pointer",
    transition: "0.3s",
    textTransform: "inherit",
    "&:hover": {
      background: "transparent",
      border: `1px solid ${theme.palette.primary.red}`,
      color: theme.palette.primary.red,
    },
  }));


  const [causesList, setCausesList] = useState([]);
  const [dialog, setDialog] = useState({
    open: true,
  });
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(props.step ? props.step : 1);
  const [id, setId] = useState(props.id ? props.id : "");
  const [validation, setValidation] = useState(true);
  const [validation1, setValidation1] = useState(true);
  const [allData, setAllData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCause, setLoadingCause] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [incomeDetails, setIncomeDetails] = useState([]);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [incomeID, setIncomeID] = useState("");
  const [value, setValue] = useState(50);
  const [label, setLabel] = useState("38");

  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  let date = moment().format("YYYY-MM-DD");
  let day = moment(date).format('dddd');

  const [giveHours, setGiveHours] = useState({
    hours: "1",
    frequency: props.giveHours?.frequency ? props.giveHours?.frequency : "daily",
    startDate: date,
    donationDay: day,
    memberID: memberData !== null ? memberData?.member?.memberID : "",
    orgID: allData && allData.organizationID?.orgID,
    stripeSetup: allData && allData.organizationID?.stripeSetup,
    causeID: allData && allData.causeID,
    causeName: "",
    causeImage: allData && allData.image,
    ruleID: ""
  })


  const [preference, setPreference] = useState({
    frequent: "Week",
    salary: "",
    workTime: "Hourly",
    monthlyIncome: "",
    weekelyIncome: "",
    hourlyIncome: label,
  });

  const handleCloseDialog = () => {
    setDialog({
      open: false,
    });
    window.location.reload();
  };

  const handleClick = (data) => {
    setGiveHours({
      ...giveHours,
      frequency: data
    });
  };



  const handleNext = () => {
    if (step !== 3) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };


  props.causeCallBack(dialog);

  useEffect(() => {
    getCauses();
    //getIncomeList();
  }, []);

  useEffect(() => {

    if ((id !== undefined && id !== "")) {

      getCauseSingle(id);
    }
  }, [id]);

  useEffect(() => {
    var newSlug = giveHours.causeName?.replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();

    setId(newSlug)
  }, [giveHours.causeName]);

  const getCauses = () => {
    setLoading(true);
    httpClient
      .get("all-charity-cause")
      .then(({ data }) => {
        if (data.success) {
          setCausesList(data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();

        }
      });
  };

  const getCauseSingle = (id) => {
    setLoadingCause(true);
    httpClient.get(`charity-cause/${id}`).then(({ data }) => {
      if (data.success) {
        setAllData(data.data)
        setLoadingCause(false);
      }
    })
  }

  useMemo(() => {
    setGiveHours({
      ...giveHours,
      orgID: allData && allData.organizationID?.orgID,
      stripeSetup: allData && allData.organizationID?.stripeSetup,
      causeID: allData && allData.causeID,
      causeName: allData && allData.name,
      causeImage: allData && allData.image,
      category: allData && allData.categoryID?.categoryName,
      hours: "1",
      frequency: props.giveHours?.frequency ? props.giveHours?.frequency : "daily",
      startDate: date,
      donationDay: day,
      ruleID: allData && allData.myDonations?.ruleID
    })
    if (allData && allData.myDonations?.donationHour) {
      setUpdated(true);
    }
  }, [allData])

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "startDate") {
      let day = moment(value).format('dddd');
      setGiveHours({
        ...giveHours,
        startDate: value,
        donationDay: day
      });
    } else {
      setGiveHours({
        ...giveHours,
        [name]: value
      });
    }
  };

  useEffect(() => {
    if (giveHours.hours && giveHours.frequency && giveHours.startDate) {
      setValidation(false)
    } else {
      setValidation(false)
    }
  }, [giveHours])

  useEffect(() => {
    if (giveHours.frequency && giveHours.causeName && giveHours.stripeSetup === 1) {
      setValidation1(false)
    } else {
      setValidation1(true)
    }
  }, [giveHours])

  useEffect(() => {
    getIncomeDetails()
  }, [])

  const getIncomeDetails = () => {
    var api = memberData === null ? `member-income/list?guestToken=${guestToken}` : `member-income/list`;
    httpClient.get(api).then(({ data }) => {
      if (data.success) {
        setIncomeDetails(data.data);
        setIncomeID(data.data[0] ? data.data[0].incomeID : "");
        setPreference({
          ...preference,
          salary: data.data[0].annualIncome,
          hourlyIncome: data.data[0].hourlyIncome,
          monthlyIncome: data.data[0].monthlyIncome,
          weekelyIncome: data.data[0].weeklyIncome,
          workTime: data.data[0].working,
        });
        setGiveHours(prevState => ({
          ...prevState,
          memberID: data.data[0] ? data.data[0].memberID : ""
        }));
      }
    });
  }

  useEffect(() => {
    if (incomeDetails.length > 0) {
      setLabel(incomeDetails[0].hourlyIncome.toFixed(2));
      setValue(reverseInterpolate(incomeDetails[0].hourlyIncome.toFixed(2)));
    }
  }, [incomeDetails])

  const handleChange1 = (e) => {
    const { name, value } = e.target;

    if (name === "salary") {

      let monthlyvalue = (value / 12).toFixed(2);
      let weekValue = (value / 52).toFixed(2);
      let hourlyValue = (weekValue / 40).toFixed(2);
      setPreference({
        ...preference,
        monthlyIncome: monthlyvalue,
        weekelyIncome: weekValue,
        hourlyIncome: hourlyValue,
        salary: value,
      });
    } else {
      setPreference({
        ...preference,
        [name]: value,
      });
    }
  };


  const handleSave = (e) => {

    if (incomeID === "") {
      // setLoadingNext(true);
      httpClient
        .post("member-income/create", {
          annualIncome: parseFloat(preference.salary),
          monthlyIncome: parseFloat(preference.monthlyIncome),
          weeklyIncome: parseFloat(preference.weekelyIncome),
          hourlyIncome: parseFloat(preference.hourlyIncome),
          working: preference.workTime,
          ...(memberData === null && { guestToken: guestToken }),
        })
        .then(({ data }) => {
          if (data.success) {
            // setLoadingNext(false);
            // setOpen(true);
            // setMessageState("success");
            // setMessage(data.message);

            setTimeout(() => {
              getIncomeDetails();
            }, 1000);
            setTimeout(() => {
              handleSubmit();
            }, 1000);
          } else {
            // setLoadingNext(false);
            // setOpen(true);
            // setMessageState("error");
            // setMessage(data.message);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.clear();

          }
        });
    } else {
      handleSubmit();
      //   setLoadingNext(true);
      //   httpClient
      //     .post(`member-income/update/${incomeID}`, {
      //       annualIncome: parseFloat(preference.salary),
      //       monthlyIncome: parseFloat(preference.monthlyIncome),
      //       weeklyIncome: parseFloat(preference.weekelyIncome),
      //       hourlyIncome: parseFloat(preference.hourlyIncome),
      //       working: preference.workTime,
      //       ...(memberData === null && { guestToken: guestToken }),
      //     })
      //     .then(({ data }) => {
      //       if (data.success) {
      //         setLoadingNext(false);
      //         setOpen(true);
      //         setMessageState("success");
      //         setMessage(data.message);
      //         setTimeout(() => {
      //           getIncomeDetails();
      //         }, 1000);
      //         setTimeout(() => {
      //           handleSubmit();

      //         }, 1000);
      //       } else {
      //         setLoadingNext(false);
      //         setOpen(true);
      //         setMessageState("error");
      //         setMessage(data.message);
      //       }
      //     })
      //     .catch((err) => {
      //       if (err.response.status === 401) {
      //         localStorage.clear();

      //       }
      //     });
      // 
    }
  }

  const handleSubmit = () => {
    setOpenPaymentDialog(true);
  }


  const callBack = (call) => {
    if (call.open === false) {
      setOpenPaymentDialog(false)
    }
  }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const handleRefetch = () => {
    getCauseSingle(id);
    // getFeaturedCategories();
  };


  function reverseInterpolate(label) {
    let x, x0, y0, x1, y1;

    if (label <= 30.62) {
      x0 = 0; y0 = 23.23; x1 = 25; y1 = 30.62;
    } else if (label <= 38) {
      x0 = 25; y0 = 30.62; x1 = 50; y1 = 38;
    } else if (label <= 144) {
      x0 = 50; y0 = 38; x1 = 75; y1 = 144;
    } else {
      x0 = 75; y0 = 144; x1 = 100; y1 = 250;
    }

    x = x0 + ((label - y0) * (x1 - x0)) / (y1 - y0);
    return Math.round(x);
  }

  const marks = [];

  function interpolate(x, x0, y0, x1, y1) {
    return y0 + ((x - x0) * (y1 - y0)) / (x1 - x0);
  }

  for (let i = 0; i <= 100; i++) {
    let label;
    if (i <= 25) {
      label = interpolate(i, 0, 23.23, 25, 30.62);
    } else if (i <= 50) {
      label = interpolate(i, 25, 30.62, 50, 38);
    } else if (i <= 75) {
      label = interpolate(i, 50, 38, 75, 144);
    } else {
      label = interpolate(i, 75, 144, 100, 250);
    }
    marks.push({ value: i, label: label.toFixed(2) });
  }

  function valuetext(value) {
    return `$${value}/hr`;
  }
  function findLabel(value) {
    for (let i = 0; i < marks.length; i++) {
      if (value <= marks[i].value) {
        return marks[i].label;
      }
    }
    return marks[marks.length - 1].label;
  }

  const handleChangeThumb = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const lab = findLabel(value);
    setLabel(lab);
  }, [value])

  useEffect(() => {
    let hourlyValue = label;
    let weekValue = hourlyValue * 40;
    let monthlyValue = (weekValue * 52) / 12;
    let salary = weekValue * 52;

    setPreference({
      ...preference,
      monthlyIncome: monthlyValue,
      weekelyIncome: weekValue,
      hourlyIncome: hourlyValue,
      salary: salary,
    });
  }, [label])

  //console.log("val", value, label);
  //console.log("giveh", giveHours);
  //console.log("step", step);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  //console.log("pqqqq", props);

  return (
    <MainDialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="lg"
    >
      <Box p={1} sx={{ border: "1px solid black", backgroundColor: "#f5f5f5", borderRadius: "10px" }}>
        {giveHours.stripeSetup === 0 &&
          <Reddiv>
            <span>Donation couldnot be made to this cause!</span>
          </Reddiv>
        }
        <MainTitle id="alert-dialog-title">

          <div>
            <Logo>
              <span></span> <span></span>
            </Logo>{" "}
            {step === 1 && <p1>Give Now</p1>}
            {step === 2 && <p1>Donating Your Hours</p1>}
            {step === 3 && <p1>Annual Income</p1>}
          </div>


          <Close onClick={handleCloseDialog} />
        </MainTitle>
        {step === 1 && (
          <DialogContent>
            <p>
              Your time is valuable, help make a difference in the world by
              donating an hour of it.
            </p>
            <CauseDiv>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {props.id ? (
                    <div style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">Cause</InputLabel>
                      <CauseName>{loadingCause ? "Fetching Cause..." : giveHours.causeName}</CauseName>
                    </div>

                  ) : (
                    <div style={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Select Your Cause
                      </InputLabel>

                      <FormControl fullWidth>
                        <>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={giveHours.causeName}
                            //label="Working"
                            //placeholder={giveHours.causeName}
                            name="causeName"
                            onChange={handleChange}
                          >
                            {causesList.map((cause) => (
                              <MenuItem key={cause.name} value={cause.name} disabled={cause.organizationID.stripeSetup === 0}>
                                {cause.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {loading && (
                            <CircularProgress
                              size={24}
                              style={{
                                position: 'absolute',
                                right: '-10%',
                                top: '40%',
                                zIndex: 1
                              }}
                            />
                          )}
                        </>
                      </FormControl>
                    </div>
                  )}

                </Grid>
                <Grid items xs={6} md={3}>
                  <LogoDiv>
                    <span></span>
                    <span></span>
                  </LogoDiv>
                </Grid>
                <Grid item xs={6} md={3} display="flex" alignItems={"center"}>
                  <ButtonsDiv>
                    <ButtonTime
                      onClick={() => handleClick("daily")}
                      style={{
                        background: giveHours.frequency === "daily" ? "#D9D9D9" : "transparent",
                      }}
                    >
                      Daily
                    </ButtonTime>
                    <ButtonTime
                      onClick={() => handleClick("weekly")}
                      style={{
                        background: giveHours.frequency === "weekly" ? "#D9D9D9" : "transparent",
                      }}
                    >
                      Weekly
                    </ButtonTime>
                    <ButtonTime
                      onClick={() => handleClick("monthly")}
                      style={{
                        background: giveHours.frequency === "monthly" ? "#D9D9D9" : "transparent",
                      }}
                    >
                      Monthly
                    </ButtonTime>
                    <ButtonTime
                      onClick={() => handleClick("yearly")}
                      style={{
                        background: giveHours.frequency === "yearly" ? "#D9D9D9" : "transparent",
                      }}
                    >
                      Yearly
                    </ButtonTime>
                  </ButtonsDiv>
                </Grid>
              </Grid>
            </CauseDiv>
          </DialogContent>
        )}
        {step === 2 && (
          <DialogContent>

            <Box pb={10}>
              <GiveText>
                <span> Giving your &nbsp;<b> Hours to </b><img src={giveHours.causeImage} style={{ marginRight: '10px', marginLeft: '10px', opacity: '100%', constraint: '100%' }} /><c1>{giveHours.causeName}</c1></span>
                <h2><strong>${label}</strong>/hr</h2>
              </GiveText>

              <HourText><h3>What is the value of your working hour?</h3></HourText>


              <ValueList>
                {isMobile ? <span2>GIVE LESS</span2> : <span>GIVE LESS</span>}
                <PrettoSlider track={false} getAriaValueText={valuetext} onChange={handleChangeThumb} defaultValue={50} valueLabelDisplay="on" valueLabelFormat={(value) => {
                  return (
                    <div style={{ textAlign: "center", opacity: 0.5 }}>
                      {/* ${label}/hr */}
                      <img src={DoubleArrow} width={50} height={50} />
                    </div>
                  );
                }}
                  value={value} />
                {isMobile ? <span2>GIVE MORE</span2> : <span>GIVE MORE</span>}
              </ValueList>

            </Box>
          </DialogContent>
        )}
        {step === 3 && (
          <DialogContent>

            <Box p={2}>
              <h4>Your Approximate Annual Salary:</h4>

              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <TextField
                    fullWidth
                    value={preference.salary}
                    name="salary"
                    label="salary"
                    placeholder="$60,000"
                    onChange={handleChange1}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Working</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={preference.workTime}
                      label="Working"
                      name="workTime"
                      onChange={handleChange1}
                    >
                      <MenuItem value="Full-time">Full-time</MenuItem>
                      <MenuItem value="Part-time">Part-time</MenuItem>
                      <MenuItem value="Hourly">Hourly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sx={12} md={8}></Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    value={preference.monthlyIncome}
                    name="monthlyIncome"
                    label="Monthly Income"
                    onChange={handleChange1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    value={preference.weekelyIncome}
                    name="weekelyIncome"
                    label="Weekely Income"
                    onChange={handleChange1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <TextField
                    value={preference.hourlyIncome}
                    name="hourlyIncome"
                    label="Hourly Income"
                    onChange={handleChange1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} textAlign="right" sx={{ marginTop: "30px" }}>
                  <RedButton onClick={handleSave}>Save Changes</RedButton>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Box textAlign={"right"} pr={2}>
            <div>
              {(step > 1 && !props.footerGive) && <GreyButton onClick={handleBack}>Go Back</GreyButton>}
              <RedButton disabled={(step === 2 && validation) || (step === 1 && validation1)} onClick={step === 2 ? handleSave : handleNext}>
                {loadingNext ? (
                  <>
                    <CircularProgress style={{ height: "20px", color: "red", width: "20px", marginRight: "10px" }} />
                    <span>Loading...</span>
                  </>
                ) : (
                  <span>Next</span>
                )}
              </RedButton>
            </div>
          </Box>
        </DialogActions>
      </Box>

      {openPaymentDialog && (
        incomeID && label &&
        <PaymentDialog giveHours={giveHours} guestToken={guestToken} handleRefetch={handleRefetch} callBack={callBack} label={parseFloat(label)} incomeDetails={incomeDetails[0]} />
      )}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </MainDialog>

  );
};

export default CauseDialog;
