import React, { useState, useEffect } from "react";
import { httpClient } from "../../appUtility/Api";
import { SignUpForm, Welcome } from "../../Components/SignUp";
import { useLocation, useNavigate } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import HelmetMetaData from "../../Components/HelmetMetaData";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SignUp = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [snackStatus, setSnackStatus] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null)
  const [submitStep, setSubmitStep] = useState(0);
  const [userData, setUserData] = useState({});

  const handleSubmit = (data1) => {
    // console.log('submit data is', data);
    var baseURL =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : (window.location.hostname === "finalhour.retailcare.com.au" ? "https://finalhour.retailcare.com.au" : "https://finalhour.org");

    setLoading(true);
    let formData = new FormData();
    formData.append("firstName", data1.firstName);
    formData.append("lastName", data1.lastName);
    formData.append("email", data1.emailAddress);
    formData.append("password", data1.password);
    formData.append("memberImageName", data1.image);
    formData.append("includeFeesOnDonation", data1.includeFeesOnDonation ? "1" : "0");
    formData.append("baseURL", baseURL);

    httpClient.post("register", formData).then(({ data }) => {
      if (data.success === true) {
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage(data.message);
        setLoading(false);
        {
          data1.acceptFriend === true &&
          localStorage.setItem("acceptFriend", id);
        }
        setTimeout(() => {
          setSubmitStep(1);
        }, 1000)
      } else {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
      }
    });

  };

  const handleSSOSignup = (response) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("firstName", response.first_name);
    formData.append("lastName", response.last_name);
    formData.append("email", response.email);
    formData.append("userName", response.email);
    formData.append("url", response.picture.data.url);
    formData.append("memberType", 1);
    formData.append("publicProfile", 1);
    formData.append("ssoType", "Facebook");
    formData.append("ssoID", response.id);
    // formData.append("baseURL",window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'http://finalhour.retailcare.com.au/' )

    httpClient.post("ssoSignup", formData).then(({ data }) => {
      if (data.success === true) {
        localStorage.setItem("token", JSON.stringify(data.data.access_token));
        localStorage.setItem("login", JSON.stringify(data.data));
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage(data.message);
        setLoading(false);
        setTimeout(() => {
          navigate('/', { state: "reload" });
        }, 1000);
      } else {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
      }
    });

  };

  const handleSSOSignupGoogle = (response) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("firstName", response.given_name);
    formData.append("lastName", response.family_name);
    formData.append("email", response.email);
    formData.append("userName", response.email);
    formData.append("url", response.picture);
    formData.append("memberType", 1);
    formData.append("publicProfile", 1);
    formData.append("ssoType", "Google");
    formData.append("ssoID", response.id);


    // formData.append("baseURL",window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'http://finalhour.retailcare.com.au/' )

    httpClient.post("ssoSignup", formData).then(({ data }) => {
      if (data.success === true) {
        localStorage.setItem("token", JSON.stringify(data.data.access_token));
        localStorage.setItem("login", JSON.stringify(data.data));
        setOpen(true);
        setSnackStatus("success");
        setSnackMessage(data.message);
        setLoading(false);
        setTimeout(() => {
          navigate('/', { state: "reload" });
        }, 1000);
      } else {
        setLoading(false);
        setOpen(true);
        setSnackStatus("error");
        setSnackMessage(typeof (data.message) === "string" ? data.message : data.message[0]);
      }
    });

  };

  useEffect(() => {
    if (location.state !== null) {
      setId(location.state.id)
    }
  }, [location.state]);


  useEffect(() => {
    if (id) {
      getInviteInfo();
    }
  }, [id]);

  const getInviteInfo = () => {
    httpClient.get(`member/getinviter?acceptCode=${id}`).then(({ data }) => {
      if (data.success) {
        setUserData(data.data);
      }
    });
  };


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //console.log('sign up location', location);

  return (
    <div>
      <HelmetMetaData
        title={"Join us to make a difference- donate your final hour to a great cause."}
      />

      {submitStep === 0 ? (
        <SignUpForm handleSubmit={handleSubmit} id={id} userData={userData} handleSSOSignupGoogle={handleSSOSignupGoogle} handleSSOSignup={handleSSOSignup} loading={loading} fallCategory={location?.state} />
      ) : (
        <Welcome />
      )}

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={snackStatus}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignUp;
