import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../appUtility/Api";
import StripeForm from "../StripeForm";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MetaIcon from "../../images/metaicon.png";
import Xicon from "../../images/Xicon.png";
import LinkedinLogo from "../../images/linkedinIcon.png";

const RedButton = styled("button")(({ theme }) => ({
  background: theme.palette.primary.red,
  color: "#fff",
  padding: "10px 20px",
  outline: "none",
  borderRadius: "25px",
  border: "1px solid transparent",
  cursor: "pointer",
  ":hover": {
    background: "brown",
  },
}));

const GreyButton = styled("button")(({ theme }) => ({
  background: "transparent",
  padding: "10px 20px",
  color: "#777",
  ouline: "none",
  borderRadius: "25px",
  border: "1px solid #777",
  marginRight: "10px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    background: "#777",
    color: "#fff",
  },
}));

const GreenBox = styled("button")(({ theme }) => ({
  background: "#33BB88",
  padding: "10px 20px",
  color: "#fff",
  ouline: "none",
  borderRadius: "25px",
  border: "1px solid transparent",
  marginRight: "10px",
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": {
    background: "transparent",
    color: "#33BB88",
  },
}));

const MainDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
  },
  [theme.breakpoints.down("md")]: {
    "& h1": {
      margin: "0",
      fontSize: "28px",
      fontWeight: "300",
    },
  },
}));

const MainTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontFamily: "Epilogue",
  "& p1": {
    fontSize: "40px",
    fontWeight: "350",
  },
  "& svg": {
    cursor: "pointer",
    position: "relative",
    top: "-35px",
    right: "-15px",
  },
  [theme.breakpoints.down("md")]: {
    "& p1": {
      fontSize: "26px",
    },
  },
}));

const Logo = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  paddingTop:"10px",
  "& span": {
    background: theme.palette.primary.red,
    padding: "5px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "12px 3px px 3px",
    [theme.breakpoints.down("md")]: {
      margin: "0",
      padding: "4px",
    },
  },
}));

const CauseDiv = styled("div")(({ theme }) => ({
  padding: "40px 15%",
  borderBottom: "1px dashed #999",
  [theme.breakpoints.down("md")]: {
    padding: "10px",
  },
}));

const ButtonsDiv = styled("div")(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
}));

const ButtonTime = styled(Button)(({ theme }) => ({
  fontSize: "19px",
  outline: "none",
  border: "1px solid transparent",
  borderRadius: "25px",
  textTransform: "inherit",
  padding: "5px 20px",
  margin: "5px",
  cursor: "pointer",
}));

const LogoDiv = styled("div")(({ theme }) => ({
  transform: "rotate(90deg)",
  display: "inline-block",
  position: "relative",
  top: "115px",
  "& span": {
    background: theme.palette.primary.red,
    padding: "15px",
    display: "inline-block",
    borderRadius: "50%",
    margin: "0px 17px",
    [theme.breakpoints.down("md")]: {
      margin: "0 10px",
      padding: "10px",
    },
  },
}));

const MainCheckout = styled("div")(({ theme }) => ({
  // margin:"0 20px",
  // borderBottom: "1px dashed #777",
  // width:"100%",
  fontWeight: "500",
}));

const CheckoutDivDivider = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
  fontWeight: "500",
  // borderBottom: "1px dashed #777",
  // paddingBottom: "60px",
}));

const CheckoutDivDividerBtn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "5px 0",
  // borderBottom: "1px dashed #777",
  fontWeight: "500",
}));

const CheckoutDiv = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
}));

const CauseName = styled("div")(({ theme }) => ({
  padding: "16.5px 14px",
  marginTop: "10px",
  border: "0.02px solid rgba(0, 0, 0, 0)",
  borderRadius: "4px",
  transition: "all 0.3s ease",
  cursor: "pointer",
  backgroundColor: "#ffffff",
  color: "#000000",
  textAlign: "center",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  "&:hover": {
    transform: "translateY(-2px)",
  },
}));

const stripePromise = loadStripe(
  window.location.hostname === "localhost"
    ? "pk_test_51MZURlJPyNbFqS59l2okiV5Iq9sKkwtfg2PMjTBuuu5Wgz1Iq4GZu39TtJwihHFrvqkR5HINp3beelqkSEdSxk2e00PgyE8J3y"
    : window.location.hostname === "finalhour.retailcare.com.au"
      ? "pk_test_51MZURlJPyNbFqS59l2okiV5Iq9sKkwtfg2PMjTBuuu5Wgz1Iq4GZu39TtJwihHFrvqkR5HINp3beelqkSEdSxk2e00PgyE8J3y"
      : "pk_live_51NM4IEHWhRrh8diCuyzABtxHPRqcNI7HZgbWL11HTOyJcyJTNlls6LZKvXeznfga5dHutlhBCTEAmqmxDBUwu50I001Nd1NOqF"
);

const PaymentDialog = (props) => {
  //console.log("payment-dia", props);
  const login = localStorage.getItem("login");
  const loginData = JSON.parse(login);

  const navigate = useNavigate();
  const [dialog, setDialog] = useState({
    open: true,
  });

  const [donationDetails, setDonationDetails] = useState({
    causeName: props && props.giveHours.causeName,
    hours: props && props.giveHours.hours,
    startDate: props.giveHours.startDate,
    donationDay: props.giveHours.donationDay,
    taxamount: (
      props.label *
      props.giveHours.hours *
      0.005
    ).toFixed(2),

    donatedAmount: (
      props.label * props.giveHours.hours
    ).toFixed(2),

    amountAfterTax: (
      props.label * props.giveHours.hours -
      props.label * props.giveHours.hours * 0.005
    ).toFixed(2),

    amountWithStripeFee: (
      (props.label * props.giveHours.hours + 0.3) /
      0.971
    ).toFixed(2),

    applicationFee: (
      props.label *
      props.giveHours.hours *
      0.1
      //0.02
    ).toFixed(2),

    amount: (
      props &&
      props.label * props.giveHours.hours * 0.1 +
      props.label * props.giveHours.hours * 0.005 +
      (props.label * props.giveHours.hours + 0.3) / 0.971
    ).toFixed(2),

    amountExcludeFees:
      props.label * props.giveHours.hours -
      (
        props.label * props.giveHours.hours * 0.1 +
        props.label * props.giveHours.hours * 0.005
      ).toFixed(2),

    // props && props.label * props.giveHours.hours,
  });
  const [time, setTime] = useState("Daily");
  const [step, setStep] = useState(1);
  const [incomeDetails, setIncomeDetails] = useState({});
  const [openStripeDialog, setOpenStripeDialog] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    setTime(props.giveHours.frequency);
    setIncomeDetails(props && props.incomeDetails);
  }, [props]);

  // console.log("give hrsssss", props.giveHours);
  // console.log("income details", incomeDetails);
  // console.log("donation details", donationDetails);

  useEffect(() => {
    props.callBack(dialog);
    // setDonationDetails({
    //     ...donationDetails,
    //     donationAmount: label * donationDetails.hours
    // })
  }, [dialog]);

  const handleCloseDialog = () => {
    // if (props.giveHours.newCauseID !== "") {
    //   window.location.reload();
    // }
    setDialog({
      open: false,
    });
  };

  const handleCloseDialog1 = () => {
    // if (props.giveHours.newCauseID !== "") {
    //   setTimeout(() => {
    //     navigate(-1, { state: "reload" });
    //   }, 1000);
    // }
    setDialog({
      open: false,
    });
  };

  const handleClick = (data) => {
    setTime(data);
  };

  // const handleNext = () => {
  //   if (step !== 3) {
  //     setStep(step + 1);
  //   }
  // };

  const handleNext = () => {
    if (step !== 2) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handlePay = () => {
    setOpenStripeDialog(true);
  };

  const sendToStripe = (call) => {
    if (call.open === false) {
      setOpenStripeDialog(false);
    }
    if (call.success === true) {
      setStep(2);
      props.handleRefetch();
    }
  };

  return (
    <MainDialog
      open={dialog.open}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
    >
      <Box p={1} sx={{ border: "1px solid black", backgroundColor: "#f5f5f5", borderRadius: "10px" }}>
        <MainTitle id="alert-dialog-title">
          <div>
            <Logo>
              <span></span> <span></span>
            </Logo>{" "}
            {step === 1 && <p1>Proceed to donate</p1>}
            {step === 2 && <p1>Donation Successful</p1>}
          </div>

          <Close onClick={handleCloseDialog} />
        </MainTitle>
        {step === 1 && (
          <DialogContent>
            <p>
              Your time is valuable, help make a difference in the world by
              donating an hour of it.
            </p>
            <CauseDiv>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <ButtonsDiv>
                    <ButtonTime
                      onClick={() => handleClick("daily")}
                      style={{
                        background: time === "daily" ? "#D9D9D9" : "transparent",
                        textDecoration:
                          time === "daily" ? "none" : "line-through",
                        pointerEvents: "none",
                      }}
                    >
                      Daily
                    </ButtonTime>
                    <ButtonTime
                      onClick={() => handleClick("weekly")}
                      style={{
                        background: time === "weekly" ? "#D9D9D9" : "transparent",
                        textDecoration:
                          time === "weekly" ? "none" : "line-through",
                        pointerEvents: "none",
                      }}
                    >
                      Weekly
                    </ButtonTime>
                    <ButtonTime
                      onClick={() => handleClick("monthly")}
                      style={{
                        background:
                          time === "monthly" ? "#D9D9D9" : "transparent",
                        textDecoration:
                          time === "monthly" ? "none" : "line-through",
                        pointerEvents: "none",
                      }}
                    >
                      Monthly
                    </ButtonTime>
                    <ButtonTime
                      onClick={() => handleClick("yearly")}
                      style={{
                        background: time === "yearly" ? "#D9D9D9" : "transparent",
                        textDecoration:
                          time === "yearly" ? "none" : "line-through",
                        pointerEvents: "none",
                      }}
                    >
                      Yearly
                    </ButtonTime>
                  </ButtonsDiv>
                </Grid>
                <Grid items xs={6} md={3}>
                  <LogoDiv>
                    <span></span>
                    <span></span>
                  </LogoDiv>
                </Grid>
                <Grid item xs={12} md={6} display="flex" alignItems={"center"}>
                  {/* <div style={{ width: "100%" }}>
                  <InputLabel id="demo-simple-select-label">Cause</InputLabel>
                  <TextField
                    value={donationDetails.causeName}
                    variant="outlined"
                    name="causeName"
                  />
                </div> */}
                  <div style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Cause</InputLabel>
                    <CauseName>{donationDetails.causeName}</CauseName>
                  </div>
                </Grid>
              </Grid>
            </CauseDiv>
          </DialogContent>
        )}
        {/* {step === 2 && (
        <DialogContent>

          <p>
            "If you give {donationDetails.hours} hours {time}, starting from{" "}
            {moment(donationDetails.startDate).format("ddd, MMM Do YYYY")}, you
            will be donating $

            {loginData?.member.includeFeesOnDonation == "1"
              ? donationDetails.donatedAmount
              : donationDetails.amount}{" "}
            {time == "daily" ? "each day" : time}."
          </p>
          <p>
            This includes:
            <p>Your Donated amount: ${props.label}</p>
            <p>Tax amount: ${donationDetails.taxamount}</p>
            <p>Application Fee: ${donationDetails.applicationFee}</p>
            <p>Stripe Fee: ${(donationDetails.amountWithStripeFee - donationDetails.donatedAmount).toFixed(2)}</p>
            <p>------------------------------------</p>
            <p>Total:{donationDetails.amount}</p>

          </p>


        </DialogContent>
      )} */}
        {step === 2 && (
          <DialogContent>
            <p>
              {/* Thank you for donating to {donationDetails.causeName}. */}
              Thank you for working on behalf of{" "}
              <strong>{donationDetails.causeName}</strong> for the final hour{" "}
              {time === "daily"
                ? "of your day."
                : time === "weekly"
                  ? "of this week."
                  : `of your working year ${new Date().getFullYear()}.`}
            </p>
            <p>
              Your contribution will play a crucial role in{" "}
              <strong>{props.giveHours.category}</strong>.
            </p>
            <p>
              With your help, we can continue making a positive difference in{" "}
              <strong>{donationDetails.causeName}</strong>.
            </p>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              p={2}
            >
              <span
                style={{
                  marginRight: "10px",
                  color: "#fff",
                  mixBlendMode: "difference",
                }}
              >
                Share :{" "}
              </span>
              <FacebookShareButton
                url={window.location.href}
                size={30}
                style={{ marginRight: "5px" }}
              >
                <img
                  src={MetaIcon}
                  alt="meta_icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </FacebookShareButton>
              <LinkedinShareButton
                url={window.location.href}
                style={{ marginRight: "5px" }}
              >
                <img
                  src={LinkedinLogo}
                  alt="linkedin_icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </LinkedinShareButton>
              <TwitterShareButton url={window.location.href}>
                <img
                  src={Xicon}
                  alt="X_icon"
                  style={{ height: "30px", width: "30px" }}
                />
              </TwitterShareButton>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Box textAlign={"right"} pr={2}>
            {/* {step > 1 && step !== 2 && (
            <GreyButton onClick={handleBack}>Go Back</GreyButton>
          )} */}

            {step === 1 &&
              // step !== 3 &&
              // (step === 1 ? (
              //   <RedButton onClick={handleNext}>{"Next"}</RedButton>
              // ) : (
              <RedButton onClick={handlePay}>{"Setup your regular payment"}</RedButton>
              // ))
            }

            {/* {step === 1 ? (
              <RedButton onClick={handleNext}>{"Next"}</RedButton>
            ) : (
            <RedButton onClick={handlePay}>{"Setup your regular payment"}</RedButton>
             )
          } */}
            {step === 2 && (
              <GreyButton onClick={handleCloseDialog1}>Close</GreyButton>
            )}
          </Box>
        </DialogActions>
      </Box>

      {openStripeDialog && (
        <Elements stripe={stripePromise}>
          <StripeForm
            sendToStripe={sendToStripe}
            // donationAmount={donationDetails.donationAmount}
            donationDetails={donationDetails}
            time={time}
            loginData={loginData}
            giveHours={props && props.giveHours}
            guestToken={props && props.guestToken}
          />
        </Elements>
      )}
    </MainDialog>
  );
};

export default PaymentDialog;
