import { Close, Facebook, Google, LinkedIn } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  styled,
  TextField,
  Snackbar,
  Button,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { httpClient } from "../../appUtility/Api";
import MuiAlert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import FacebookLoginRender from "react-facebook-login/dist/facebook-login-render-props";
// import ReactFacebookLogin from "react-facebook-login";
// import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FacebookProvider, LoginButton, } from 'react-facebook';

// import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from '@react-oauth/google';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
//import LinkedInLogin from 'react-linkedin-login-oauth2';
// You can use provided image shipped by this package or using your own
// import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';

import axios from 'axios';
import { scryRenderedComponentsWithType } from "react-dom/test-utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    padding: "20px",
    borderRadius: "25px",
    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },
  },
  "& h1": {
    margin: "0",
    fontWeight: "300",
  },
}));

const MainTitle = styled(DialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "& svg": {
    cursor: "pointer",
    position: "relative",
    top: "-35px",
    right: "-15px",
  },
}));

const FacebookButton = styled("button")(({ theme }) => ({
  background: "#4c69ba",
  color: "#fff",
  padding: "10px",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  border: "1px solid transparent",
  cursor: "pointer",
  transition: "0.1s",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fontSize: "19px",
  },
  "&:hover": {
    boxShadow:
      "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px",
  },
}));
const GoogleButton = styled("button")(({ theme }) => ({
  background: "#ffffff",
  color: "#888",
  padding: "10px",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  border: "1px solid grey",
  cursor: "pointer",
  transition: "0.1s",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fontSize: "19px"
  },
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px"
  }
}))

const LinkedInButton = styled("button")(({ theme }) => ({
  background: "#0a66c2",
  color: "#fff",
  padding: "10px",
  borderRadius: "2px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  border: "1px solid transparent",
  cursor: "pointer",
  transition: "0.1s",
  boxShadow: "none",
  "& .MuiSvgIcon-root": {
    fontSize: "19px"
  },
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px"
  }
}))

const LoginDialog = (props) => {
  const APPID = "151634951157132";
  useEffect(() => {
    loadFacebookInit();
  }, []);

  const loadFacebookInit = () => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: APPID,
        cookie: true,  // enable cookies to allow the server to access
        // the session
        // autoLogAppEvents : true,
        xfbml: true,
        version: 'v2.5'
      });
    };


    // Load the SDK asynchronously

    (function (d, s, id) {
      //console.log("Loading fb api");
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }


  const RedButton = styled(Button)(({ theme }) => ({
    background: formValid ? theme.palette.primary.red : "#bbb",
    color: "#fff",
    padding: "10px 40px",
    outline: "none",
    borderRadius: "25px",
    border: "1px solid transparent",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid ${theme.palette.primary.red} !important`,
      background: "transparent !important",
      color: `${theme.palette.primary.red} !important`,
    },
    "& span": {
      color: "#fff",
    },
  }));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [dialog, setDialog] = useState({
    open: true,
    success: false,
  });
  const [loginDetails, setLoginDetails] = useState({
    username: "",
    password: "",
  });
  const [response, setResponse] = useState({
    email: "",
    ssoType: "",
    ssoID: "",
  });

  const recaptchaRef = createRef();
  const [capcha, setCapcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [facebookAccessToken, setFacebookAccessToken] = useState('');
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");

  useEffect(() => {
    setCapcha("");
    setLoginDetails({
      username: "",
      password: "",
    });
    setResponse({
      email: "",
      ssoType: "",
      ssoID: "",
    });
  }, []);

  useEffect(() => {
    if (loginDetails.username && loginDetails.password && capcha) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [loginDetails, capcha]);

  useEffect(() => {
    props.loginCallback(dialog);
  }, [props, dialog]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  const handleCloseDialog = () => {
    setDialog({
      open: false,
    });
  };

  const handleForgot = (e) => {
    navigate("/forgot-password");
    setDialog({
      ...dialog,
      open: false,
    });
  };
  const handleSignUp = (e) => {
    navigate("/sign_up");
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    httpClient
      .post("login", {
        username: loginDetails.username,
        password: loginDetails.password,
        GRecaptchaResponse: capcha,
      })
      .then(({ data }) => {
        // console.log("data", data);
        if (data.success) {
          localStorage.setItem("token", JSON.stringify(data.data.access_token));
          localStorage.setItem("login", JSON.stringify(data.data));
          localStorage.removeItem("guestToken");
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          setTimeout(function () {
            navigate("/dashboard");
            window.location.reload();
          }, 2000);
        }
        if (data.success === false) {
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          // setMessage(data.message[0] !== "T" ? data.message[0] : data.message);
          setOpen(true);
          setMessageState("error");
          setLoading(false);
        }
      });
  };

  const handleSSOLogin = (response) => {
    // e.preventDefault();
    setLoading(true);
    httpClient
      .post("ssoLogin", {
        email: response.email,
        ssoType: "Facebook",
        ssoID: response.id,
      })
      .then(({ data }) => {
        // console.log("data", data);
        if (data.success) {
          localStorage.setItem("token", JSON.stringify(data.data.access_token));
          localStorage.setItem("login", JSON.stringify(data.data));
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          setTimeout(function () {
            navigate("/dashboard");
            window.location.reload();
          }, 2000);
        }
        if (data.success === false) {
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          // setMessage(data.message[0] !== "T" ? data.message[0] : data.message);
          setOpen(true);
          setMessageState("error");
          setLoading(false);
        }
      });
  };

  const handleSSOLoginGoogle = (response) => {
    // e.preventDefault();
    setLoading(true);
    httpClient
      .post("ssoLogin", {
        email: response.email,
        ssoType: "Google",
        ssoID: response.id,
      })
      .then(({ data }) => {
        // console.log("data", data);
        if (data.success) {
          localStorage.setItem("token", JSON.stringify(data.data.access_token));
          localStorage.setItem("login", JSON.stringify(data.data));
          setOpen(true);
          setMessageState("success");
          setMessage(data.message);
          setLoading(false);
          setTimeout(function () {
            navigate("/dashboard");
            window.location.reload();
          }, 2000);
        }
        if (data.success === false) {
          setMessage(
            typeof data.message === "string" ? data.message : data.message[0]
          );
          // setMessage(data.message[0] !== "T" ? data.message[0] : data.message);
          setOpen(true);
          setMessageState("error");
          setLoading(false);
        }
      });
  };

  const handleFacebook = () => {
    window.FB.login(function (response) {
      //console.log("facebook res si >>>", response);
      if (response.authResponse) {
        setFacebookAccessToken(response.authResponse.accessToken);
        console.log('Facebook access token:', response.authResponse.accessToken);
        console.log('Welcome!  Fetching your information.... ');
        window.FB.api('/me', { fields: 'id, first_name, last_name, email, picture' }, function (response) {
          console.log('User profile:', response);
          handleSSOLogin(response);
          // Store the user's profile information or use it as needed
        });
      } else {
        console.log('User cancelled login or did not fully authorize.');
      }
    }, { scope: 'email' });


  };
  const handleFacebookError = (error) => {
    console.log('Facebook error:', error);
  };
  const responseGoogle = (response) => {
    console.log("google res", response);
  };

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const [googleLoginCalled, setGoogleLoginCalled] = useState(false);

  const handleGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      setGoogleLoginCalled(true); // Set the flag to indicate that handleGoogle was called
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(() => {
    if (googleLoginCalled) {
      const fetchProfileData = async () => {
        try {
          if (user) {
            const response1 = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
              headers: {
                Authorization: `Bearer ${user.access_token}`,
                Accept: 'application/json'
              }
            });
            setProfile(response1.data);
            const response = response1.data
            console.log("profile", response);
            setResponse(response);
            console.log("profileyyy", response);
            handleSSOLoginGoogle(response);
            console.log("profilezzz", response);
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchProfileData();
    }
  }, [googleLoginCalled, user]);

  // console.log("profile-last", profile);


  //   const { linkedInLogin } = useLinkedIn({
  //   clientId: '86vhj2q7ukf83q',
  //   redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
  //   onSuccess: (code) => {
  //     console.log('linke dncode', code);
  //   },
  //   onError: (error) => {
  //     console.log('linked error',error);
  //   },
  // });

  // console.log('site', process.env.REACT_APP_SITEKEY);


  return (
    <>
      <MainDialog
        open={dialog.open}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <MainTitle id="alert-dialog-title">
          <h1>Login</h1>

          <Close onClick={handleCloseDialog} />
        </MainTitle>
        <DialogContent>
          <Box pt={2} pb={2}>
            <InputLabel>Username/Email</InputLabel>
            <TextField
              name="username"
              variant="outlined"
              value={loginDetails.username}
              onChange={handleChange}
              fullWidth
            />
          </Box>
          <Box pb={2}>
            <InputLabel>Password</InputLabel>
            <TextField
              name="password"
              variant="outlined"
              value={loginDetails.password}
              onChange={handleChange}
              type="password"
              fullWidth
            />
          </Box>
          <Box pb={2}>
            <small style={{ cursor: "pointer" }} onClick={handleForgot}>
              Forgot password?
            </small>
          </Box>
          <Box pb={2}>
            <small>
              Not logged in yet? </small>
            <small style={{ cursor: "pointer", color: "royalblue" }} onClick={handleSignUp}>
              Click here for Signup.
            </small>
          </Box>
          <Box mb={3}>
            <ReCAPTCHA
              ref={recaptchaRef}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              sitekey={(window.location.hostname === "localhost" || window.location.hostname === "finalhour.retailcare.com.au") ? "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" : process.env.REACT_APP_SITEKEY}
              // sitekey={(window.location.hostname === "localhost" || window.location.hostname === "finalhour.retailcare.com.au")  ? "6LdN4KkoAAAAAEwbImplwzUx0qwddlEdB3wMI8Ds": process.env.REACT_APP_SITEKEY}
              // sitekey={process.env.REACT_APP_SITEKEY}
              onChange={(e) => setCapcha(recaptchaRef.current.getValue())}
            />
          </Box>

          <Box mt={2} mb={3} style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", justifyContent: "center", gap: "20px" }}>
            {/* <ReactFacebookLogin
                  // appId={APPID}
                  appId="1088597931155576"
                  autoLoad={true}
                  fields="name,email,picture"
                  callback={responseFacebook} 
                  render={renderProps => (
                    <FacebookButton > <Facebook fontSize="small" />  Sign in with facebook </FacebookButton>
                  )}
                  /> */}

            {/* <FacebookProvider appId={APPID}>
                  <FacebookButton
                    scope="email"
                    onError={handleFacebookError}
                    onSuccess={responseFacebook}
                  >
                    <Facebook fontSize="small" />  Sign in with facebook
                  </FacebookButton>
                </FacebookProvider> */}


            {/* commneted */}
            {/* <FacebookButton
                    onClick={handleFacebook}
                  >
                    <Facebook fontSize="small" />  Sign in with facebook
                  </FacebookButton> */}

            {/* <GoogleButton onClick={() => handleGoogle()}>
                  Sign in with Google 
                  <Google fontSize="small" />  
                  </GoogleButton> */}
            {/* commneted */}




            {/* 
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    console.log('google response siss>>>',credentialResponse);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                /> */}

            {/* <LinkedInButton onClick={linkedInLogin}> <LinkedIn fontSize="small" /> Sign in with LinkedIn </LinkedInButton> */}

          </Box>

          <Box textAlign={"right"}>
            {loading ? (
              <RedButton disabled={true}>
                <CircularProgress
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                  }}
                />{" "}
                <span>Loading</span>
              </RedButton>
            ) : (
              <RedButton onClick={handleSubmit} disabled={formValid ? false : true}>
                Login
              </RedButton>
            )}
          </Box>
        </DialogContent>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          onClose={handleClose}
          style={{
            position: "fixed",
            top: isMobile ? "8%" : "18%",
            left: isMobile ? "0" : "30%",
            transform: isMobile ? "" : "translate(-50%, -50%)",
            zIndex: 1000, // Set your desired z-index value
          }}
        >
          <Alert
            onClose={handleClose}
            severity={messageState}
            sx={{ width: isMobile ? "100%" : "40%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </MainDialog>

      {/* <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={messageState}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar> */}



    </>
  );
};

export default LoginDialog;
