import {
  Container,
  Grid,
  Pagination,
  PaginationItem,
  styled,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpClient } from "../../../appUtility/Api";
import BreadCrumb from "../../../Components/BreadCrumb";
import MainDonationBox from "./MainDonationBox";
import GivingPreference from "../GivingPreference";
import NoImage from './../../../images/no-pictures.png'
import CrisisImage from './../../../images/emergency.png'
import { Add, Download } from "@mui/icons-material";

const DonationDiv = styled("div")(({ theme }) => ({
  background: "#CCA17A",
  padding: "40px 0",
  "& h2": {
    margin: "0",
    paddingBottom: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },
}));

const GiveButton = styled("button")(({ theme }) => ({
  padding: "6px 20px",
  borderRadius: "100px",
  border: "1px solid black !important",
  color: "black",
  background: "pink",
  marginTop: "10px",
  transition: "0.3s",
  cursor: "pointer",
  "& span": {
    fontSize: "18px"

  },
  [theme.breakpoints.down("md")]: {
    padding: "5px 10px",
    marginLeft: "5px",
    marginTop: "0",
  },
  "&:hover": {
    background: `${theme.palette.primary.red} !important`,
    color: "#fff !important",
    border: `1px solid gray !important`,
  },
}));

const PaginationBox = styled("div")(({ theme }) => ({
  padding: "40px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#4A4A4A",
  "& span": {
    fontSize: "16px",
    marginRight: "10px",
  },
  "& button": {
    color: "#4A4A4A",
  },
}));

const Causes = styled("div")(({ theme }) => ({
  padding: "10px 0",
  display: "grid",
  gridTemplateColumns: "1fr 2.5fr 1.5fr 1fr 1fr 1fr",
  rowGap: "10px",
  width: "100%",
  borderBottom: "1px dashed #ffffff",
  justifyItems: "start",
  alignItems: "center",
  "& img": {
    width: "70px",
    height: "70px",
    borderRadius: "50%",
    border: "1px solid black",
    objectFit: "cover",
  },
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center", // Add this line
  },

}));

const CauseName = styled(Box)(({ theme }) => ({
  // width: "360px",
  margin: "0 0px",
  padding: "10px",
  fontSize: "16px",
  fontWeight: "700",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBottom: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const CrisisImg = styled("div")(({ theme }) => ({
  "& img": {
    width: "20px !important",
    height: "20px !important",
    position: "absolute",
    bottom: "60px !important",
    left: "65px !important",
    border: "none !important",
    borderRadius: "initial !important"
  },
}));

const CauseButtonDiv = styled("div")(({ theme }) => ({
  // width: "175px",
  textAlign: "right",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    //textAlign: "left",
    marginTop: "10px",
    flexDirection: "column",
    textAlign: "center",
  },
}));

const DonationHistory = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [donationList, setDonationList] = useState([]);
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(0);
  const [total, setTotal] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [lastPage, setLastPage] = useState("");
  const [count, setCount] = useState(20);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    getRecentDonations();
  }, []);

  const getRecentDonations = () => {
    setLoading(true);
    httpClient.get("donation-rule/list").then(({ data }) => {
      if (data.success) {
        setDonationList(data.data);
        setLoading(false);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setTotal(data.meta.total);
        setCount(data.meta.per_page);
        setCurrPage(data.meta.current_page);
        setLastPage(data.meta.last_page);
      }
    }).catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        navigate("/")
      }
    })
  };

  const handleChangePage = (e, value) => {
    setLoading(true);
    httpClient
      .get(`donation-rule/list?page=${value}&pagination=${count}`)
      .then(({ data }) => {
        if (data.success) {
          setDonationList(data.data);
          setLoading(false);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setTotal(data.meta.total);
          setCount(data.meta.per_page);
          setCurrPage(data.meta.current_page);
          setLastPage(data.meta.last_page);
        }
      }).catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/")
        }
      })
  };

  const handleClickCause = (cause) => {
    var newSlug = cause.causeName
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, { state: cause });
  };

  const handleClickCauseGive = (cats) => {
    var newSlug = cats.name
      .replace(/[` ~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "-")
      .toLowerCase();
    navigate(`/causes/detail/${newSlug}`, {
      state: { cause: cats, give: true },
    });
  };

  console.log("donationLL", donationList);
  return (
    <div>
      <BreadCrumb
        header="Dashboard"
        subHeader1="Dashboard"
        Link1="/dashboard"
        subHeader2="Donation History"
      />
      <DonationDiv>
        <Container maxWidth="xl">
          <h2>Donation History</h2>

          {/* <Grid container spacing={2}>
            {!loading ? (
              donationList.length > 0
              ? donationList.map((donation) => (
                  <Grid item xs={12} key={donation.ruleID}>
                    <MainDonationBox
                      date={moment(donation.paidDate).format(
                        "ddd, MMM Do YYYY"
                      )}
                      cause={donation.causeID.causeName || ""}
                      hours={donation.donationRuleID.donationHour + " Hrs"}
                      frequency={donation.donationRuleID.donationFrequency}
                      info={donation.data}
                      amount={"$" + donation.amount_paid}
                      handleClickCause={() =>handleClickCause(donation.causeID)}
                      invoiceID={donation.invoiceID}
                    />
                  </Grid>
                ))
              : "No recent donations!"
            ): "Fetching Donation History.."}

            <Grid item xs={12}>
              <PaginationBox>
                <span>
                  {from}-{to} of {total} items
                </span>
                
                <Pagination
                  count={lastPage}
                  page={currPage}
                  onChange={(e, value) => handleChangePage(e, value)}
                  showFirstButton
                  showLastButton
                  shape="rounded"
                >
                  <PaginationItem
                    page={currPage}
                   
                  />
                </Pagination>
                
              </PaginationBox>
            </Grid> 
             
          </Grid> */}

          <Grid container spacing={2}>

            {!isMobile &&
              <Causes sx={{ borderBottom: "none" }}>
                <strong></strong>
                <strong></strong>
                <strong>Frequency</strong>

                <strong>Amount</strong>
                <strong>Download Receipt</strong>
                <strong>Action</strong>
              </Causes>
            }

            {!loading ? (
              donationList.length > 0 ?
                donationList.map((don) => (
                  <Causes id={don.id}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', position: "relative", cursor: "pointer" }}
                      onClick={() => handleClickCause(don.data.cause)}
                    >
                      <img src={don.data.cause.image || NoImage} alt="logo" style={{ marginRight: '10px', opacity: '100%', constraint: '100%' }} />
                      {don.data.cause.crisis === 1 ? (
                        <CrisisImg>
                          <img src={CrisisImage} />
                        </CrisisImg>
                      ) : (
                        ""
                      )}

                    </div>

                    <CauseName>
                      <>
                        <p>{don.data.cause.name}</p>
                      </>
                    </CauseName>
                    <CauseName>
                      <>
                        <p>{don.donationRuleID?.donationFrequency?.charAt(0).toUpperCase() + don.donationRuleID?.donationFrequency?.slice(1)}</p>
                      </>
                    </CauseName>

                    <CauseName>
                      <>
                        <p>${don.total}</p>
                      </>
                    </CauseName>
                    <CauseName>
                      <>
                        <IconButton onClick={() => handleClickCause(don.causeID)}><Download /></IconButton>
                      </>
                    </CauseName>

                    <CauseButtonDiv>
                      <GiveButton onClick={() => handleClickCauseGive(don.data.cause)}>
                        <Add sx={{ fontSize: "20px", color: "red", }} /> <span style={{}}>Adjust</span>
                      </GiveButton>
                    </CauseButtonDiv>
                  </Causes>
                )) : <Causes><div></div><div></div>No Recurring Donations! </Causes>) : (<Causes><div></div><div></div>Fetching Donations...</Causes>)}

            <Grid item xs={12}>
              <PaginationBox>
                <Pagination
                  count={lastPage}
                  page={currPage}
                  onChange={(e, value) => handleChangePage(e, value)}
                  variant="outlined"
                  color="primary"
                >


                </Pagination>
              </PaginationBox>


            </Grid>
          </Grid>
        </Container>
      </DonationDiv>
    </div>
  );
};

export default DonationHistory;
